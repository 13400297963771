//env파일에서 봇 userId 가져오기
export const getBotUserIds = () => {
  return [process.env.REACT_APP_BOT_USER_ID_1].filter(Boolean);
};

//게시글 userId가 botUserIds에 포함되어 있는지 확인
export const isBotUser = (userId: string): boolean => {
  const botUserIds = getBotUserIds();
  return botUserIds.includes(userId);
};

export const renderBotIcon = (isAdmin: boolean, userId: string): string => {
  return isAdmin && isBotUser(userId) ? ' 🤖' : '';
};
