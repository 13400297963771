import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getTotalPosts } from '../../apis/post';
import Pagination from '../Common/Pagination';
import {
  TotalPostData,
  useAppSelector,
  Announcements,
  IntegratedAnnouncements,
} from '../../constants/types';
import { isBotUser, renderBotIcon } from '../../util/botUserHelpers';
function MobileTotalPost() {
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);

  const { pageNumber } = useParams<{ pageNumber: string }>();
  const [rankerPostList, setRankerPostList] = useState<TotalPostData[]>([]);
  const [normalPostList, setNormalPostList] = useState<TotalPostData[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);
  const navigate = useNavigate();
  const handlePostClick = (boardNumber: number, postId: string) => {
    navigate(`/post/${boardNumber}/${postId}`);
    window.scrollTo(0, 0);
  };
  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  useEffect(() => {
    getTotalPosts(currentPage).then((res) => {
      setAnnouncements(res.announcements);
      setIntegratedAnnouncements(res.integratedAnnouncements);
      setRankerPostList(res.posts.rankerPosts);
      setNormalPostList(res.posts.normalPosts);
      setTotalPage(res.totalPageNumber);
    });
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, '', `/post/totalpost/${page}`);
    window.location.reload();
  };

  const renderNormalPosts = (posts: TotalPostData[]) => {
    return (
      <div>
        {posts.map((post) => {
          const botIcon = renderBotIcon(isAdmin, post.userId);

          return (
            <div
              key={post.id}
              className='items-center border-b border-[#A4A4A4]'
              onClick={() => handlePostClick(post.boardNumber, post.id)}>
              <div className='pb-2 pt-3 pl-2 flex items-center '>
                <div className=' flex-shrink-0 items-center'>
                  {/* 여기서 hot populartiy 수정  */}
                  {post.viewCount < 150 ? (
                    <div className='pl-1'></div>
                  ) : (
                    <img
                      src='/assets/icon/hot_label_mobile.svg'
                      alt='hot_label_mobile'
                      className='w-14'
                    />
                  )}
                </div>
                <div className='flex text-base font-normal'>
                  <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                    {post.boardName}
                  </div>
                  {post.title}
                  {post.mainImage && (
                    <img
                      src='/assets/icon/10duk_photo.svg'
                      alt='image'
                      className='ml-1 w-3'
                    />
                  )}
                </div>
                <div className='flex items-center'>
                  {post.commentCount !== 0 && (
                    <div className=' text-hotduk font-bold px-2'>
                      [{post.commentCount}]
                    </div>
                  )}
                </div>
              </div>
              <div className='flex pl-2 pb-1'>
                <div className='flex px-1 pb-2 items-center'>
                  {post.ranker ? (
                    <div className='flex items-center'>
                      <p className='flex text-xs text-[#6B6B6B] font-normal'>
                        익명{botIcon}
                      </p>
                      <img
                        src='/assets/icon/ranker_crown.svg'
                        alt='ranker_crown'
                        className='w-6 px-1 mr-1'
                      />
                    </div>
                  ) : (
                    <div className='pr-7 text-xs text-[#6B6B6B] font-normal'>
                      익명{botIcon}
                    </div>
                  )}
                </div>
                <div className='flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]'>
                  <img
                    src='/assets/icon/eye.svg'
                    alt='eye'
                    className='w-4 mr-1'
                  />
                  {post.viewCount}
                </div>
                <div className='flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]'>
                  <img
                    src='/assets/icon/thumbsup_title.svg'
                    alt='like'
                    className='w-[14px] mr-1'
                  />
                  {post.voteUpCount}
                </div>
                <div className='flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]'>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 3600000
                  ) < 24 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt))
                        .getHours()
                        .toString()
                        .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <>
                      {Math.floor(
                        (Date.now() - Date.parse(post.createdAt)) / 86400000
                      ) > 364 ? (
                        <div>
                          {`${new Date(
                            new Date(post.createdAt)
                          ).getFullYear()}/${(
                            new Date(new Date(post.createdAt)).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            new Date(post.createdAt)
                          )
                            .getDate()
                            .toString()
                            .padStart(2, '0')}`}{' '}
                        </div>
                      ) : (
                        <div>
                          {`${(
                            new Date(new Date(post.createdAt)).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            new Date(post.createdAt)
                          )
                            .getDate()
                            .toString()
                            .padStart(2, '0')}`}{' '}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderRankerPosts = (posts: TotalPostData[]) => {
    return (
      <div>
        {posts.map((post) => {
          const botIcon = renderBotIcon(isAdmin, post.userId);

          return (
            <div
              key={post.id}
              className='bg-[#FFF8EA] items-center border-b border-[#A4A4A4]'
              onClick={() => handlePostClick(post.boardNumber, post.id)}>
              <div className='pb-2 pt-3 pl-2 flex items-center'>
                <div className=' flex-shrink-0 items-center'>
                  {/* 여기서 hot populartiy 수정  */}
                  {post.viewCount < 150 ? (
                    <img
                      src='/assets/icon/rank_label_mobile.svg'
                      alt='rank_label_mobile'
                      className='mr-2 w-12'
                    />
                  ) : (
                    <img
                      src='/assets/icon/hot_label_mobile.svg'
                      alt='hot_label_mobile'
                      className='w-14'
                    />
                  )}
                </div>
                <div className='flex text-base font-semibold'>
                  <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                    {post.boardName}
                  </div>
                  {post.title}
                  {post.mainImage && (
                    <img
                      src='/assets/icon/10duk_photo.svg'
                      alt='image'
                      className='ml-1 w-3'
                    />
                  )}
                </div>
                <div className='flex items-center'>
                  {post.commentCount !== 0 && (
                    <div className=' text-hotduk font-bold px-2'>
                      [{post.commentCount}]
                    </div>
                  )}
                </div>
              </div>
              <div className='flex pl-2 pb-1'>
                <div className='flex px-1 pb-2 items-center'>
                  <p className='flex text-xs text-[#6B6B6B] font-normal'>
                    익명{botIcon}
                  </p>
                  <img
                    src='/assets/icon/ranker_crown.svg'
                    alt='ranker_crown'
                    className='w-6 px-1 mr-1'
                  />
                </div>

                <div className='flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]'>
                  <img
                    src='/assets/icon/eye.svg'
                    alt='eye'
                    className='w-4 mr-1'
                  />
                  {post.viewCount}
                </div>
                <div className='flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]'>
                  <img
                    src='/assets/icon/thumbsup_title.svg'
                    alt='like'
                    className='w-[14px] mr-1'
                  />
                  {post.voteUpCount}
                </div>
                <div className='flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]'>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 3600000
                  ) < 24 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt))
                        .getHours()
                        .toString()
                        .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <>
                      {Math.floor(
                        (Date.now() - Date.parse(post.createdAt)) / 86400000
                      ) > 364 ? (
                        <div>
                          {`${new Date(
                            new Date(post.createdAt)
                          ).getFullYear()}/${(
                            new Date(new Date(post.createdAt)).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            new Date(post.createdAt)
                          )
                            .getDate()
                            .toString()
                            .padStart(2, '0')}`}{' '}
                        </div>
                      ) : (
                        <div>
                          {`${(
                            new Date(new Date(post.createdAt)).getMonth() + 1
                          )
                            .toString()
                            .padStart(2, '0')}/${new Date(
                            new Date(post.createdAt)
                          )
                            .getDate()
                            .toString()
                            .padStart(2, '0')}`}{' '}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <div>
        <div
          key={post.id}
          className='items-center bg-[#F1F1F1] border-b border-[#A4A4A4]'
          onClick={() => handleAnnouoncementClick(post.id)}>
          <div className='py-4 pl-2 flex items-center '>
            <div className=' flex-shrink-0 items-center'>
              <img
                src='/assets/icon/notice_label_mobile.svg'
                alt='notice_label_mobile'
                className='w-[54px] mr-1'
              />
            </div>
            <div className='flex text-base font-semibold items-center'>
              {post.title}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const slicedPosts = posts.slice(0, 2);
    return (
      <div>
        {slicedPosts.map((post, index) => (
          <div
            key={post.id}
            className='items-center bg-[#F1F1F1] border-b border-[#A4A4A4]'
            onClick={() => handleAnnouoncementClick(post.id)}>
            <div className='py-4 pl-2 flex items-center '>
              <div className=' flex-shrink-0 items-center'>
                {index === 0 ? (
                  <img
                    src='/assets/icon/notice_label_mobile_patch.svg'
                    alt='notice_label_mobile_patch'
                    className='w-[54px] mr-1'
                  />
                ) : (
                  <img
                    src='/assets/icon/notice_label_mobile.svg'
                    alt='notice_label_mobile'
                    className='w-[54px] mr-1'
                  />
                )}
              </div>
              <div
                className={`flex text-base font-semibold items-center ${
                  index === 0 ? 'text-[#4373EF]' : 'text-[#B355CA]'
                }`}>
                {post.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className='border-b border-[#A4A4A4]'></div>

      {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
        <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
      ) : (
        <></>
      )}
      {announcements && announcements.length !== 0 ? (
        <>{renderAnnouncementPost(announcements)}</>
      ) : (
        <></>
      )}
      {rankerPostList.length !== 0 ? (
        <>{renderRankerPosts(rankerPostList)}</>
      ) : (
        <></>
      )}
      {normalPostList.length !== 0 ? (
        <>{renderNormalPosts(normalPostList)}</>
      ) : (
        <></>
      )}

      <div className='flex justify-center items-center mt-5'>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
      {/* <div className="flex justify-center items-center mx-auto mt-5 pr-2">
        <button
          id="createPostButton"
          type="button"
          onClick={goToCreatePost}
          className="bg-[#222222] text-white items-center font-semibold text-xl rounded-[100px] h-12 w-36"
        >
          글쓰기
        </button>
      </div> */}
    </div>
  );
}

export default MobileTotalPost;
